import { Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import ContactModal from "./contact-modal";
import { patientContact } from '../../patient/components/models/patient-encounter';

interface contactAccordionProps {
    patientContact: patientContact,
    patientId: number,
    encounterId: number
}

const ContactAccordionContents = (props: contactAccordionProps) => {

    const { patientContact, patientId, encounterId } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalCancel = () => {
        setIsModalOpen(false);
    }

    const handleModalSubmit = () => {
        setIsModalOpen(false);
    }
    return <>
        <Grid container item direction="column" spacing={2} xs={12} md={12}>
            <Grid container item justifyContent="space-between" >
                <Typography variant="h1">Name & Address</Typography>
                <IconButton onClick={() => setIsModalOpen(true)} className="edit-button" size="large">
                    <EditIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <Typography variant="body2" className="mb-3">
                    {patientContact?.firstName} {patientContact?.middleName} {patientContact?.lastName}
                </Typography>
                <address>
                    <Typography variant="body2">{patientContact?.primaryAddress.streetAddress1}</Typography>
                    {patientContact?.primaryAddress.streetAddress2 && 
                        <Typography variant="body2">{patientContact?.primaryAddress.streetAddress2}</Typography>}
                    <Typography variant="body2">{patientContact?.primaryAddress.city}, {patientContact?.primaryAddress?.state?.stateCode || ""} {patientContact?.primaryAddress.zipCode}</Typography>
                </address>
            </Grid>
            <Grid item>
                <Typography variant="h1">Contact</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" className="mt-1 mb-3">
                    {patientContact?.email}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {patientContact?.primPhoneNum} {patientContact?.primPhoneType}
                </Typography>
            </Grid>
        </Grid>
        <ContactModal
            open={isModalOpen}
            handleModalSubmit={handleModalSubmit}
            handleModalCancel={handleModalCancel}
            patientId={patientId}
            encounterId={encounterId}
            patientContact={patientContact}
        />
    </>;
}

export default ContactAccordionContents;