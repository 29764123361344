import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getConfiguration } from '../../configuration/config-settings';
import { Grid } from '@mui/material';
import { PaymentIntents } from '../../../patient/components/models/payment-method';
import { patientService } from "src/patient/services/patient-service";
interface OnlinePaymentProps {
  amount: number;
  isOnlinePayment: boolean;
}

export interface OnlinePaymentRef {
  confirmPayment: () => Promise<string>;
  isPaymentReady: boolean;
}

const OnlinePayment = forwardRef<OnlinePaymentRef, OnlinePaymentProps>(
  ({ amount, isOnlinePayment }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const [, setMessage] = useState<string>("");
    const [clientSecret, setClientSecret] = useState<string>("");
    const [isPaymentReady, setIsPaymentReady] = useState<boolean>(false);
    const [isCreatingIntent, setIsCreatingIntent] = useState(false);
    const [isUpdatingIntent, setIsUpdatingIntent] = useState(false);

    const createPaymentIntent = async (amount: number) => {
      if (isCreatingIntent) return;

      try {
        setIsCreatingIntent(true);
        const paymentData: PaymentIntents = {
          amount: amount * 100,
        };
        const response: any = await patientService.createPaymentIntents(paymentData);
        console.log(response, 'created response====>');

        const data = await response.entity;
        if (response.statusCode === 200) {
          setClientSecret(data.client_secret);
          setIsPaymentReady(true);
        } else {
          throw new Error(data.error?.message || "Failed to create payment intent.");
        }
      } catch (error) {
        setMessage("Failed to create payment intent.");
        setIsPaymentReady(false);
        console.error("Error creating payment intent:", error);
      }
      finally {
        setIsCreatingIntent(false);
      }
    };

    const updatePaymentIntent = async (amount: number) => {
      if (isUpdatingIntent) return;

      try {
        setIsUpdatingIntent(true);
        const paymentIntentId = clientSecret.split("_secret_")[0];

        const paymentData: PaymentIntents = {
          amount: amount * 100,
        };
        const response: any = await patientService.updatePaymentIntents(paymentIntentId, paymentData);
        console.log(response, 'updated response====>');

        const data = await response.entity;
        if (response.statusCode === 200) {
          setClientSecret(data.client_secret);
          setIsPaymentReady(true);
        } else {
          throw new Error(data.error?.message || "Failed to update payment intent.");
        }
      } catch (error) {
        setMessage("Failed to update payment intent.");
        setIsPaymentReady(false);
        console.error("Error updating payment intent:", error);
      }
      finally {
        setIsUpdatingIntent(false);
      }
    };

    useEffect(() => {
      if (isOnlinePayment && amount > 0 && !clientSecret) {
        createPaymentIntent(amount);
      } else if (isOnlinePayment && amount > 0 && clientSecret) {
        updatePaymentIntent(amount);
      }
    }, [isOnlinePayment, amount, clientSecret]);

    useImperativeHandle(ref, () => ({
      confirmPayment: async () => {
        if (!stripe || !elements) {
          return "Stripe or Elements has not been initialized.";
        }

        try {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
          });

          if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
              return error.message ?? "An error occurred while processing your payment.";
            } else {
              return "An unexpected error occurred. Please try again.";
            }
          }

          if (paymentIntent) {
            switch (paymentIntent.status) {
              case "succeeded":
                return "Success! Payment received.";
              case "processing":
                return "Payment is being processed. We'll notify you when it's complete.";
              case "requires_payment_method":
                return "Payment failed. Please try another payment method.";
              default:
                return "An unexpected issue occurred. Please contact support.";
            }
          }

          return "An unknown error occurred. Please try again.";
        } catch (err) {
          console.error("Error confirming payment:", err);
          return "An unexpected error occurred during payment confirmation.";
        }
      },
      isPaymentReady,
    }));


    const getPromise = () => {
      let promise: Promise<Stripe | null> = new Promise(() => { });
      if (config.stripe_key) {
        promise = loadStripe(config.stripe_key);
      }
      return promise;
    };


    const config = getConfiguration();
    const promise = getPromise();

    return (
      <Grid item xs={8} aria-autocomplete='none'>
        {isOnlinePayment && clientSecret && (
          <Elements stripe={promise} options={{ clientSecret }}>
            <PaymentElement id="payment-element" />
          </Elements>
        )}
      </Grid>
    );
  }
);
export default OnlinePayment
