export interface PaymentMethod {
  paymentMethodId?: number | string;
  externalPaymentMethodId: string;
  paymentMethodTypeId: number;
  externalPaymentId?: string;
  receiptEmail?: string;
  payorId?: number | string;
  paymentAmt?: number;
  nameOnCard?: string;
  scheduleStartDt?: string;
  externalAccountId?: number;
  externalCustomerId?: number;
  paymentTypeId?: string;
  cardPayment?: boolean;
  brand?: string;
  last4?: number;
  status?: string;
  isDefault?: Boolean;
  ach?: boolean;
  userId?: number;
  expiration?: string | null;
  verification_id?: string;
}

export const emptyPaymentMethod: PaymentMethod = {
  externalPaymentMethodId: '',
  paymentMethodTypeId: 0,
  externalPaymentId: '', // stripe ID
}

export const PAYMENT_METHOD_TYPES: {[key: string]: PaymentMethod} = {
  CARD: {
    externalPaymentMethodId: 'card',
    paymentMethodTypeId: 1
  },
  ACH: {
    externalPaymentMethodId: 'bank_account',
    paymentMethodTypeId: 2
  }
}
export interface PaymentIntents {
  amount: number;
}

