import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { DialogActionButton, saveCallbackStatus, Toggle, TextField } from "@finpay-development/shared-components";
import { Utils } from "../../shared/utils";
import { stateAbbrevLookup, stateAbbrevs } from "../../shared/misc/us-states";
import { patientContact } from "../../patient/components/models/patient-encounter";
import { State } from "../../shared/model/state";
import { commonPatientInfo } from "../../shared/validation/schemas";
import { RootState } from "../../shared/state/root-reducer";
import { useDispatch, useSelector } from "react-redux";
import { updateContactInfo } from "../../patient/state/patient-thunk";
import { AppDispatch } from "../../shared/state/store";
import {PhoneType} from '../../shared/model/primary-contact';

interface ContactModalProps {
  open: boolean;
  handleModalCancel: () => void;
  handleModalSubmit: () => void;
  patientId: number;
  encounterId: number;
  patientContact:patientContact;
}
const ContactModal = (props: ContactModalProps) => {
  const { open, handleModalSubmit, handleModalCancel, patientContact, patientId, encounterId } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const isSaving = useSelector((state: RootState) => {
    return state.patientContext.isSaving
  })

  const mapToSaveModel = () => {
    const formValues = formRef.current.values;
    const stateInfo = stateAbbrevLookup(formValues.state)
    const state = {
      stateId: stateInfo.id,
      stateCode: formValues.state,
      stateName: stateInfo.name,
    } as State;

    return {
      "primaryAddress": {
          "streetAddress1": formValues.streetAddress1,
          "streetAddress2": formValues.streetAddress2,
          "city": formValues.city,
          "state": state,
          "zipCode": formValues.zipCode,
      },
      "primPhoneNum": formValues.firstPhone,
      "primPhoneType": formValues.isFirstPhoneMobile ? PhoneType.mobile : PhoneType.home,
      "secPhoneNum": formValues.secondPhone,
      "secPhoneType": formValues.isSecondPhoneMobile ? PhoneType.mobile : PhoneType.home,
      "leaveVMFl": formValues.leaveVoicemail,
      "smsConsentFl": formValues.receiveSMSMessages,
    }
  }

  async function handleSave() {
    const patientContactToSave = mapToSaveModel();
    await dispatch(updateContactInfo({ patientContact: patientContactToSave, patientId: patientId, encounterId: encounterId }));
    handleModalSubmit();
  }


  const initialValuesEdit = {
    title: patientContact.title,
    firstName: patientContact.firstName,
    middleName: patientContact.middleName,
    lastName: patientContact.lastName,
    email: patientContact.email,
    retypeEmail: patientContact.email,
    streetAddress1: patientContact.primaryAddress.streetAddress1,
    streetAddress2: patientContact.primaryAddress.streetAddress2,
    city: patientContact.primaryAddress.city,
    state: patientContact.primaryAddress?.state?.stateCode,
    zipCode: patientContact?.primaryAddress.zipCode,
    firstPhone: patientContact?.primPhoneNum,
    secondPhone: patientContact?.secPhoneNum,
    isFirstPhoneMobile: patientContact?.primPhoneType === PhoneType.mobile,
    isSecondPhoneMobile:  patientContact?.secPhoneType === PhoneType.mobile,
    leaveVoicemail: patientContact?.leaveVMFl,
    receiveSMSMessages: patientContact?.smsConsentFl
  };

  const validationSchema = Yup.object(commonPatientInfo);

  const titles = ['Mr.', 'Mrs.', 'Ms.', 'Dr.'];

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }
  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Name & Address</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValuesEdit}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => { }}
        >
          {(formik) => {
            return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Name</Typography>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["title"] && formik.errors["title"]
                    }
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    placeholder="Select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                    disabled={true}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {titles.map((title: string) => (
                      <MenuItem key={title} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["firstName"] && formik.errors["firstName"]
                    }
                    label="First Name"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={2} item>
                  <TextField
                    label="M.I."
                    maxLength={2}
                    name="middleName"
                    value={formik.values.middleName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    error={
                      formik.touched["lastName"] && formik.errors["lastName"]
                    }
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Contact Information</Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["email"] && formik.errors["email"]
                    }
                    label="Email"
                    name="email"
                    placeholder="Enter Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeEmail"] && formik.errors["retypeEmail"]
                    }
                    label="Retype Email"
                    name="retypeEmail"
                    placeholder="Re-Enter Email Address"
                    value={formik.values.retypeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="subtitle2">Address</Typography>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress1"] && formik.errors["streetAddress1"]
                    }
                    label="Street Address 1"
                    name="streetAddress1"
                    minLength={3}
                    placeholder="Enter Street Address"
                    value={formik.values.streetAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress2"] && formik.errors["streetAddress2"]
                    }
                    label="Street Address 2"
                    name="streetAddress2"
                    minLength={3}
                    value={formik.values.streetAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["city"] && formik.errors["city"]
                    }
                    label="City"
                    name="city"
                    placeholder="Enter City"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      (formik.touched["state"] && formik.errors["state"])
                    }
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {stateAbbrevs.map((state: string) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["zipCode"] && formik.errors["zipCode"]
                    }
                    label="Zip Code"
                    minLength={5}
                    maxLength={5}
                    name="zipCode"
                    placeholder="Enter Zip Code"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["firstPhone"] && formik.errors["firstPhone"]
                    }
                    label="Phone"
                    maxLength={12}
                    name="firstPhone"
                    placeholder="Enter Phone Number"
                    value={formik.values.firstPhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("firstPhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Toggle
                    leftText="Mobile"
                    rightText="Home"
                    name="isFirstPhoneMobile"
                    value={formik.values.isFirstPhoneMobile}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["secondPhone"] && formik.errors["secondPhone"]
                    }
                    label="Phone"
                    name="secondPhone"
                    placeholder="Enter Phone Number"
                    maxLength={12}
                    value={formik.values.secondPhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("secondPhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Toggle
                    leftText="Mobile"
                    rightText="Home"
                    name="isSecondPhoneMobile"
                    value={formik.values.isSecondPhoneMobile}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={3} item style={{ marginBottom: -12 }}>
                  <Typography variant="h4">
                    Leave Voicemail
                  </Typography>
                </Grid>
                <Grid xs={9} item style={{ marginBottom: -12 }}>
                  <Typography variant="h4">
                    Receive SMS Messages
                  </Typography>
                </Grid>
                <Grid xs={3} item>
                  <Toggle
                    name="leaveVoicemail"
                    value={formik.values.leaveVoicemail}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={9} item>
                  <Toggle
                    name="receiveSMSMessages"
                    value={formik.values.receiveSMSMessages}
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Form>
          )}}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton || !isSaving}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={() => { }} // handled in thunk
          handleCallbackCancel={handleModalCancel}
        />
      </DialogActions>
    </Dialog>
  );
}

export default ContactModal;
