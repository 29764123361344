import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../scss/components/_btn-group.scss';
import '../../../scss/components/_icons.scss';
import '../../../scss/pages/admin/_admin-clients.scss';

import {
  Checkbox,
  DatePicker,
  TextField,
  Toggle,
} from '@finpay-development/shared-components';
import { AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { Box, MenuItem, Typography, Grid } from '@mui/material';
import { formatNumberToUSD } from '../../../shared/calculators';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { Utils } from '../../../shared/utils';
import { CustomFormikProps } from '../../../shared/model/formik-props';
import StripeBankField from '../../../stripe/components/StripeBankField';
import StripeCardField from '../../../stripe/components/StripeCardField';
import { StripePaymentMethod } from 'src/patient/components/models/stripe-payment-method';
import { setDownPaymentToken, setRecurringPaymentToken, setPatientPaymentMethods } from 'src/patient/state/patient-slice';
import { PatientPaymentProgram } from 'src/patient/components/models/patient-payment-program';
import { useStripe } from '@stripe/react-stripe-js';
import { patientHelper, patientService } from '../../../patient/services/patient-service';
import OnlinePayment, { OnlinePaymentRef } from "./online-payment";

interface ITakePaymentModalContentProps {
  formik: any;
  patientIOCData: any;
  patientDetails: any;
  disallowedPaymentTypes: string[];
  isOnlyAccountHolder: boolean;
  disallowedPatientAPI: boolean;
  hasPaymentReason: boolean;
  onlinePaymentRef: React.RefObject<OnlinePaymentRef>;
  isPaymentProgramV5?: boolean
}

export const paymentToggleTypes = {
  ONLINE_PAYMENT: 'Online Payment',
  CREDIT_CARD: 'Credit Card',
  ACH: 'ACH',
  PAID_AT_FACILITY: 'Paid at Facility',
  CHECK: 'Check',
};

const TakePaymentModalContent = (props: ITakePaymentModalContentProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    formik,
    patientIOCData,
    patientDetails,
    disallowedPaymentTypes,
    isOnlyAccountHolder,
    disallowedPatientAPI,
    hasPaymentReason,
    onlinePaymentRef,
    isPaymentProgramV5
  } = props;
  const currentValues = formik.values;
  const patientPaymentProgram = patientIOCData?.patientPaymentProgram?.length > 0
    ? patientIOCData?.patientPaymentProgram[patientIOCData?.patientPaymentProgram?.length - 1]
    : {} as PatientPaymentProgram;

  const [isStripeEmpty, setIsStripeEmpty] = useState(true); // stripe input field error / empty state

  const recurringAmt = patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt || 0;

  const stateFields = {
    stripeCardToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.token
    ),
    stripeRecurringCardToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.token
    ),
    stripeBankToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.bankToken
    ),
    stripeRecurringBankToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.bankToken
    ),
    currentContact: useSelector((state: RootState) => state?.patientContext?.selectedPatient?.contact),
    transactions: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter?.patientTransactions?.length > 0 && state.patientContext.selectedEncounter.patientTransactions
    }),
    selectedEncounter: useSelector(
      (state: RootState) => state.patientContext.selectedEncounter
    ),
  }

  const { currentContact, transactions, selectedEncounter } = stateFields;

  const [paymentMethods, setPaymentMethods] = useState([]);

  const stripe = useStripe();
  const doAnyAchPaymentsOrPaymentMethodsExist = patientHelper.checkNonRefundedAchTransactionsOrPaymentMethods(transactions, paymentMethods)

  // let downPayments = transactions ? patientHelper.getNonRefundedDownPayments(transactions) : [];
  const isZeroDown = patientIOCData?.patientPaymentProgram?.length > 0 && patientIOCData?.patientPaymentProgram?.[patientIOCData?.patientPaymentProgram.length - 1].downPmtAmt === 0;

  let patientsAndChampionsList: any[] = [];
  if (!isOnlyAccountHolder) {
    patientsAndChampionsList = Utils.getPatientsAndChampionsList(patientDetails || {}, patientIOCData, false); // payor can be anyone in this list
  } else {
    patientsAndChampionsList = Utils.getPatientsAndChampionsList(patientDetails || {}, patientIOCData, true);
    if (patientsAndChampionsList.length > 1) {
      patientsAndChampionsList = [patientsAndChampionsList[1]]
    } else {
      patientsAndChampionsList = [{
        id: patientIOCData?.patientId,
        contactId: currentContact.contactId,
        name: `${currentContact?.firstName} ${currentContact?.lastName}`,
        email: currentContact?.email,
        isGuarantor: true,
      }];
    }
  }

  const getReceiptEmail = (id: string) => {
    let newEmail = '';
    patientsAndChampionsList.forEach((item: { id: number, email: string }) => {
      if (item.id === parseInt(id)) {
        newEmail = item.email;
      }
    });
    formik.setFieldValue('receiptEmail', newEmail, false);
  }

  useEffect(() => {
    if (patientsAndChampionsList.length > 0 && patientsAndChampionsList[0].id) {
      formik.setFieldValue('payorName', patientsAndChampionsList[0].id, false);
      formik.setFieldValue('receiptEmail', patientsAndChampionsList[0].email!, false);
      fetchExistingPaymentMethods(patientsAndChampionsList[0].contactId || 0)
    }
  }, [])

  const stripeTokenCallback = (data: { token?: string, cardToken?: string, bankToken?: string }) => {
    const {token, cardToken, bankToken} = data
    if (token && cardToken) {
      dispatch(setDownPaymentToken({token: token, cardToken: cardToken}))
    } else if (bankToken) {
      dispatch(setDownPaymentToken({bankToken: bankToken}))
    }
  }

  const stripeRecurringPaymentTokenCallback = (data: { token?: string, cardToken?: string, bankToken?: string }) => {
    const {token, cardToken, bankToken} = data
    if (token && cardToken) {
      dispatch(setRecurringPaymentToken({token: token, cardToken: cardToken}))
    } else if (bankToken) {
      dispatch(setRecurringPaymentToken({bankToken: bankToken}))
    }
  }

  const stripeDisabledCallback = (isDisabledFromStripe: boolean) => {
    setIsStripeEmpty(isDisabledFromStripe)
  };

  const mapToPatientPaymentMethods = (paymentMethodsRes: any) => {
    const defaultSource = paymentMethodsRes?.default_source;
    return (paymentMethodsRes?.paymentMethods || []).map((method: any) => ({
      object: method.type,
      id: method.id,
      customer: method.customer,
      name: method?.metadata?.['metaData.nameOnCard'] || method?.billing_details?.name,
      brand: method?.card?.brand,
      last4: method?.[method.type]?.last4,
      isDefaultSource: defaultSource === method.id,
      paymentMethodType: method?.metadata?.['metaData.paymentMethodType'],
    }));
  }

  const fetchExistingPaymentMethods = async (contactId: number) => {
    let paymentMethodsRes;
    try {
      paymentMethodsRes = await patientService.listPaymentMethodsByContactId(contactId);
    } catch (err) {
      console.log('Error on fetch payment list ===>', err);
    }
    let paymentMethodsData = paymentMethodsRes?.entity
      || {
        default_source: null,
        paymentMethods: [],
    };
    paymentMethodsData = mapToPatientPaymentMethods(paymentMethodsData);
    setPaymentMethods(paymentMethodsData);

    if (paymentMethodsData?.length) {
      const defaultSource = paymentMethodsData.find((method: any) => method.isDefaultSource);
      formik.setFieldValue('paymentSources', JSON.stringify(defaultSource), false);
      !formik.values.useExistingSource && formik.setFieldValue('useExistingSource', true, false);
      formik.setFieldValue('isDefault', true, false);
    } else {
      formik.setFieldValue('paymentSources', undefined, false);
      formik.values.useExistingSource && formik.setFieldValue('useExistingSource', false, false)
      formik.setFieldValue('isDefault', true, false);
    }
    setTimeout(() => {formik.validateForm();}, 100);
  }

  const maxPaymentAmt = 200;
  //  downPayments && downPayments?.length > 0 ? getMaxPaymentAmt() : patientPaymentProgram?.downPmtAmt
  
  interface DownPaymentsShape {
    payorName: string,
    amount: number,
    paymentType: string
    nameOnCard?: string,
    bankName?: string,
    routingNumber?: string,
    accountNumber?: string,
    retypeAccountNumber?: string,
    firstName?: string,
    lastName?: string,
  }

  function getDateInFuture(days: number) {
    let todayFullDate = new Date();
    const todayDayOfMonth = todayFullDate.getDate();
    return todayFullDate.setDate(todayDayOfMonth + days);
  }

  const handleInputChange = (name: string, value: any) => {
    switch (name) {
      case 'payorName':
        formik.setFieldValue('payorName', value, false);
        getReceiptEmail(value);
        if (value !== 'add-new-payor') {
          const contactId = (patientsAndChampionsList.find((champion) => (champion.id === value)))?.contactId;
          if (contactId) {
            fetchExistingPaymentMethods(contactId);
          }
          return;
        } else {
          dispatch(setPatientPaymentMethods([]));
          formik.setFieldValue('useExistingSource', false, false);
          formik.setFieldValue('isDefault', true, false);
          formik.setFieldValue('paymentSources', '', false);
        }
        break;
      case 'useExistingSource':
        formik.setFieldValue('useExistingSource', value, false);
        !value && !formik.values.isDefault && formik.setFieldValue('isDefault', true, false);
        formik.setFieldValue('paymentMethodType', paymentToggleTypes.CREDIT_CARD, false);
        break;
      case 'paymentSources':
        formik.setFieldValue('paymentSources', value);
        !!JSON.parse(value)?.isDefaultSource !== formik.values.isDefault
          && formik.setFieldValue('isDefault', !!JSON.parse(value)?.isDefaultSource, false);
        break;
      default:
        break;
    }
  }

  const isRecurringAvailable = () => {
    if (formik.values.paymentMethodType === paymentToggleTypes.CHECK || formik.values.paymentMethodType === paymentToggleTypes.PAID_AT_FACILITY) {
      return false;
    }

    const currentChampion = patientsAndChampionsList.find((champion) => champion.id === formik.values.payorName);

    return !!recurringAmt && !!currentChampion?.isGuarantor;
  }

  const canUseForRecurringPayment = (paymentType: string, payorName: string, formik: CustomFormikProps) => {
    const canUseForRecurringPayment = (paymentType !== paymentToggleTypes.PAID_AT_FACILITY) && (Utils.checkIfSelectedPayorIsGuarantor(
      parseInt(payorName),
      patientsAndChampionsList,
    ))

    if (!canUseForRecurringPayment && formik?.values.recurringPayment) {
      // reset the 'can use for recurring payment' toggle to false if the selected payor isn't the guarantor or patient
      formik?.setFieldValue('recurringPayment', false)
    }
    return canUseForRecurringPayment
  }

  return (
    <div>
      <Grid container spacing={2} className="icon-colors">
        <Grid item xs={6} className="mt-2">
          <Typography variant="subtitle2">
            Patient Name
          </Typography>
        </Grid>
        <Grid item xs={6} className="mt-2">
          <Typography variant="subtitle2" align="right">
            {patientDetails?.contact?.fullName}
          </Typography>
        </Grid>
        <Grid item xs={6} className="mt-2">
          <Typography variant="subtitle2">
            Pending Remaining Balance
          </Typography>
        </Grid>
        <Grid item xs={6} className="mt-2">
          <Typography variant="subtitle2" align="right">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule?.pfrPendingBalance || patientIOCData?.pfrAmt || 0)}
          </Typography>
        </Grid>
        <Grid item xs={6} className="mt-2">
          <Typography variant="subtitle2">
            Payment Amount
          </Typography>
        </Grid>
        <Grid item xs={6} className="mt-2">
          <Box display="flex" justifyContent="flex-end" className="field-container">
            <TextField
              error={
                formik.touched["amount"] && formik.errors["amount"]
              }
              label="Amount"
              startAdornment={<AttachMoneyIcon/>}
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
              // disabled={isZeroDown}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Payor
          </Typography>
        </Grid>
        <Grid item xs={6} test-id="take-payment-select-payor">
          <TextField
            select={true}
            error={
              formik.touched["payorName"] && formik.errors["payorName"]
            }
            label="Payor Name"
            name="payorName"
            value={formik.values.payorName}
            placeholder="Select"
            onChange={(e: any) => { handleInputChange('payorName', e.target.value); }}
            onBlur={formik.handleBlur}
            className="state-field"
          >
            {patientsAndChampionsList.map((payor: { name: string, id: number | undefined }) => (
              <MenuItem key={payor.id} value={payor.id}>
                {payor.name}
              </MenuItem>
            ))}
            {!isOnlyAccountHolder && <MenuItem value="add-new-payor">+ Add new payor</MenuItem>}
          </TextField>
        </Grid>
        <Grid item xs={8} className="mt-2">
          <Box display="flex" justifyContent="flex-start" className="field-container">
            <TextField
              error={
                formik.touched["receiptEmail"] && formik.errors["receiptEmail"]
              }
              label="Payor Email (for Receipt)"
              name="receiptEmail"
              value={formik.values.receiptEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
        {formik.values.payorName === 'add-new-payor'
        && formik.values.paymentMethodType === paymentToggleTypes.ACH && (
          <Grid item xs={8} className="mt-2">
            <Box display="flex" justifyContent="flex-start" className="field-container">
              <TextField
                error={
                  formik.touched["nameOnAccount"] && formik.errors["nameOnAccount"]
                }
                label="Name on Account"
                name="nameOnAccount"
                value={formik.values.nameOnAccount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </Grid>
        )}

        {formik.values.payorName !== 'add-new-payor' && paymentMethods.length > 0 && (
          <>
            <Grid xs={12} item>
              <Typography variant="h4">
                Use Existing Payment Method
              </Typography>
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Toggle
                name="useExistingSource"
                value={formik.values.useExistingSource}
                onClick={() => {
                  handleInputChange('useExistingSource', !formik.values.useExistingSource)
                }}
                formik={formik}
              />
            </Grid>
          </>
        )}
        {formik.values.useExistingSource ? (
          <>
            <Grid xs={8} item data-testid="existing-payment-methods">
              <TextField
                select={true}
                error={
                  formik.touched["paymentSources"] && formik.errors["paymentSources"]
                }
                label="Existing Payment Methods"
                name="paymentSources"
                value={formik.values.paymentSources}
                className="pb-4"
                onChange={(e: any) => { handleInputChange('paymentSources', e.target.value); }}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="-1">
                  Select Payment Source
                </MenuItem>
                {paymentMethods && paymentMethods?.length > 0 && paymentMethods.map((source: StripePaymentMethod, index: number) => (
                  <MenuItem
                    key={(source?.id) ? source.id : index }
                    value={JSON.stringify(source)}
                    data-testid={source.object}
                  >
                    {Utils.convertPaymentMethodToReadableInfo(source)}{source.isDefaultSource ? '(default)' : ''}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Checkbox
                  id="isDefault"
                  label="Set as default"
                  name="isDefault"
                  checked={formik.values.isDefault}
                  onChange={(e: any) => {
                    formik.setFieldValue('isDefault', e.target.checked, false);
                  }}
                />
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {formik.values.recurringPayment === true && (
              <>
                <Grid item xs={8} className="mt-2">
                  <Typography variant="subtitle2">
                    Recurring Payment Amount
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="pr-1 mt-2"
                  >
                    <Typography variant="subtitle2">
                      {`$${patientIOCData?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt}`}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
            {(patientIOCData?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt !== 0) &&
              (canUseForRecurringPayment(formik.values.paymentMethodType, formik.values.payorName, formik)) && (
                <>
                  <Grid item xs={12}>
                    {(!formik.values.recurringPayment && formik.values.paymentMethodType === "ACH") && (
                      <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                        <Typography variant="h4" color="error" className="py-2 px-1">
                          If you plan to use the current ACH bank account to make an ACH recurring payment, you
                          must select 'yes' on the Use For Recurring Payment toggle.
                          ACH will not be an option in the recurring payment modal if you make an ACH down
                          payment.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h4'>
                      Use for Recurring Payment?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Toggle
                      name="recurringPayment"
                      value={formik.values.recurringPayment}
                      formik={formik}
                      onClick={() => {
                        doAnyAchPaymentsOrPaymentMethodsExist && !formik.values?.recurringPayment &&
                        formik.setFieldValue('paymentMethodType', 'Credit Card')
                      }}
                    />
                  </Grid>
                </>
              )}
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Payment Source
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: -12}} className="ml-2">
              <Typography variant="h4">
                Payment Type
              </Typography>
            </Grid>
            <Grid item xs={12} className="mb-2" test-id="take-payment-type-buttongroup">
              <div className="buttongroup">
                {!disallowedPaymentTypes.includes(paymentToggleTypes.CREDIT_CARD) && (
                  <>
                    <input
                      id="1"
                      type="radio"
                      value={paymentToggleTypes.CREDIT_CARD}
                      name="paymentMethodType"
                      checked={formik.values.paymentMethodType === paymentToggleTypes.CREDIT_CARD}
                      onChange={formik.handleChange}
                      disabled={isZeroDown}
                    />
                    <label htmlFor="1">{paymentToggleTypes.CREDIT_CARD}</label>
                  </>
                )}
                {!disallowedPaymentTypes.includes(paymentToggleTypes.ACH) && (
                  <>
                    <input
                      id="2"
                      type="radio"
                      value={paymentToggleTypes.ACH}
                      name="paymentMethodType"
                      checked={formik.values.paymentMethodType === paymentToggleTypes.ACH}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="2">{paymentToggleTypes.ACH}</label>
                  </>
                )}
                {!disallowedPaymentTypes.includes(paymentToggleTypes.PAID_AT_FACILITY) && (
                  <>
                    <input
                      id="3"
                      type="radio"
                      value={paymentToggleTypes.PAID_AT_FACILITY}
                      name="paymentMethodType"
                      checked={formik.values.paymentMethodType === paymentToggleTypes.PAID_AT_FACILITY}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="3" test-id="Cash">{isZeroDown ? "Zero Down Payment" : "Cash"}</label>
                  </>
                )}
                {!disallowedPaymentTypes.includes(paymentToggleTypes.CHECK) && (
                  <>
                    <input
                      id="4"
                      type="radio"
                      value={paymentToggleTypes.CHECK}
                      name="paymentMethodType"
                      checked={formik.values.paymentMethodType === paymentToggleTypes.CHECK}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="4">{paymentToggleTypes.CHECK}</label>
                  </>
                )}
                {isPaymentProgramV5 && !disallowedPaymentTypes.includes(paymentToggleTypes.ONLINE_PAYMENT) && (
                  <>
                    <input
                      id="5"
                      type="radio"
                      value={paymentToggleTypes.ONLINE_PAYMENT}
                      name="paymentMethodType"
                      checked={formik.values.paymentMethodType === paymentToggleTypes.ONLINE_PAYMENT}
                      onChange={formik.handleChange}
                      disabled={isZeroDown}
                    />
                    <label htmlFor="5">{paymentToggleTypes.ONLINE_PAYMENT}</label>
                  </>
                )}
              </div>
            </Grid>
            {isPaymentProgramV5 && formik.values.paymentMethodType === paymentToggleTypes.ONLINE_PAYMENT && (
              <OnlinePayment amount={formik.values.amount} isOnlinePayment={true} ref={onlinePaymentRef} />
            )}
            {formik.values.paymentMethodType === paymentToggleTypes.CREDIT_CARD && (
              <>
                <Grid item xs={8} aria-autocomplete='none'>
                  <TextField
                    autoComplete={false}
                    error={
                      formik.touched["nameOnCard"] && formik.errors["nameOnCard"]
                    }
                    label="Name on Card"
                    placeholder="Enter Name on Card"
                    name="nameOnCard"
                    value={formik.values.nameOnCard}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="center">
                    <Checkbox
                      id="isDefault"
                      label="Set as default"
                      name="isDefault"
                      checked={formik.values.isDefault}
                      onChange={(e: any) => {
                        formik.setFieldValue('isDefault', e.target.checked, false);
                      }}
                      disabled={!paymentMethods.length}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className="my-2" aria-autocomplete='none'>
                  <StripeCardField
                    disabledCallback={stripeDisabledCallback}
                    tokenCallback={stripeTokenCallback}
                    data={
                      {
                        name: formik.values.nameOnCard,
                        address_country: 'US'
                      }
                    }
                    recurringPayment={formik.values.recurringPayment}
                    recurringPaymentTokenCallback={stripeRecurringPaymentTokenCallback}
                  />
                </Grid>
                {formik.values.recurringPayment === true && (
                  <Grid item xs={4}>
                    <DatePicker
                      error={
                        Utils.isValidDate(formik.values.paymentDay) &&
                        formik.touched["paymentDay"] &&
                        formik.errors["paymentDay"]
                      }
                      label="Recurring Payment Date"
                      minDate={getDateInFuture(1)}
                      maxDate={getDateInFuture(60)}
                      daysToDisable={[29, 30, 31]}
                      value={formik.values.paymentDay}
                      onChange={(date: Date) => {
                        formik.setFieldValue("paymentDay", date.toISOString(), false);
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            {formik.values.paymentMethodType === paymentToggleTypes.ACH && (
              <>
                <Grid item xs={6} aria-autocomplete='none'>
                  <TextField
                    autoComplete={false}
                    error={
                      formik.touched["bankName"] && formik.errors["bankName"]
                    }
                    label="Bank Name"
                    name="bankName"
                    value={formik.values.bankName}
                    placeholder="Enter Bank Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={6} aria-autocomplete='none'>
                  <TextField
                    autoComplete={false}
                    error={
                      formik.touched["routingNumber"] && formik.errors["routingNumber"]
                    }
                    label="Routing Number"
                    name="routingNumber"
                    value={formik.values.routingNumber}
                    placeholder="Enter Routing Number"
                    maxLength={9}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={6} aria-autocomplete='none'>
                  <TextField
                    autoComplete={false}
                    error={
                      formik.touched["accountNumber"] && formik.errors["accountNumber"]
                    }
                    label="Account Number"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    placeholder="Enter Account Number"
                    maxLength={17}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={6} aria-autocomplete='none'>
                  <TextField
                    autoComplete={false}
                    error={
                      formik.touched["retypeAccountNumber"] && formik.errors["retypeAccountNumber"]
                    }
                    label="Re-Enter Account Number"
                    name="retypeAccountNumber"
                    value={formik.values.retypeAccountNumber}
                    placeholder="Re Enter Account Number"
                    maxLength={17}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} aria-autocomplete='none'>
                  <Box display="flex" justifyContent="right">
                    <Checkbox
                      id="isDefault"
                      label="Set as default"
                      name="isDefault"
                      checked={formik.values.isDefault}
                      onChange={(e: any) => {
                        formik.setFieldValue('isDefault', e.target.checked, false);
                      }}
                      disabled={!paymentMethods.length}
                    />
                  </Box>
                </Grid>
                {formik.values.recurringPayment === true && (
                  <Grid item xs={4}>
                    <DatePicker
                      error={
                        Utils.isValidDate(formik.values.paymentDay) &&
                        formik.touched["paymentDay"] &&
                        formik.errors["paymentDay"]
                      }
                      label="Recurring Payment Date"
                      minDate={getDateInFuture(1)}
                      maxDate={getDateInFuture(60)}
                      daysToDisable={[29, 30, 31]}
                      value={formik.values.paymentDay}
                      onChange={(date: Date) => {
                        formik.setFieldValue("paymentDay", date.toISOString(), false);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} aria-autocomplete='none'>
                  <StripeBankField
                    tokenCallback={stripeTokenCallback}
                    bankData={
                      {
                        country: 'US',
                        currency: 'usd',
                        routing_number: formik.values.routingNumber,
                        account_number: (formik.values.accountNumber === formik.values.retypeAccountNumber) ? formik.values.retypeAccountNumber.padStart(12, '0') : '',
                        bank_name: formik.values.bankName,
                        account_holder_name: Utils.getPayorName(
                          {
                            payorId: parseInt(formik?.values?.payorName),
                            patientId: patientDetails?.patientId,
                            selectedPatient: patientDetails,
                            selectedEncounter: patientIOCData
                          }
                        ),
                        account_holder_type: 'individual',
                      }
                    }
                    recurringPayment={formik?.values?.recurringPayment}
                    recurringPaymentTokenCallback={stripeRecurringPaymentTokenCallback}
                  />
                </Grid>
              </>
            )}
            {formik.values.payorName === 'add-new-payor'
              && (formik.values.paymentMethodType === paymentToggleTypes.CHECK || formik.values.paymentMethodType === paymentToggleTypes.PAID_AT_FACILITY) && (
                <>
                  <Grid item xs={6} aria-autocomplete='none'>
                    <TextField
                      error={
                        formik.touched["firstName"] && formik.errors["firstName"]
                      }
                      label="First Name"
                      name="firstName"
                      value={formik.values.firstName}
                      placeholder="Enter First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={6} aria-autocomplete='none'>
                    <TextField
                      error={
                        formik.touched["lastName"] && formik.errors["lastName"]
                      }
                      label="Last Name"
                      name="lastName"
                      value={formik.values.lastName}
                      placeholder="Enter Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </>
              )}
            {isPaymentProgramV5 && (
              <>
                <Grid item xs={12}>
              {formik.values.paymentMethodType !== paymentToggleTypes.ONLINE_PAYMENT && <Toggle
                name="isCaptured"
                value={formik.values.isCaptured}
                formik={formik}
                leftText='Capture'
                rightText='Authorize'
                onClick={() => {
                  !formik.values?.isCaptured && formik.setFieldValue('isCaptured', !formik.values.isCaptured)
                }}
              />}
            </Grid> 
            </>
            )}
          </>
        )}
        {isRecurringAvailable() && false && (
          <>
            <Grid xs={12} item>
              <Typography variant="h4">
                Use as Recurring Source
              </Typography>
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Toggle
                name="useAsRecurringSource"
                value={formik.values.useAsRecurringSource}
                onClick={() => {
                  handleInputChange('useAsRecurringSource', !formik.values.useAsRecurringSource)
                }}
                formik={formik}
              />
            </Grid>
            {formik.values.useAsRecurringSource && (
              <>
                <Grid item xs={6} className="mt-2">
                  <Typography variant="subtitle2">
                    Recurring Amount
                  </Typography>
                </Grid>
                <Grid item xs={6} className="mt-2">
                  <Typography variant="subtitle2" align="right">
                    {formatNumberToUSD(recurringAmt)}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
        {hasPaymentReason && (
        <Grid item xs={8} className="mt-2">
          <Box display="flex" justifyContent="flex-start" className="field-container-100">
            <TextField
              error={
                formik.touched["paymentReason"] && formik.errors["paymentReason"]
              }
              label="Payment Reason"
              name="paymentReason"
              placeholder="Enter Payment Reason"
              value={formik.values.paymentReason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
        )}
      </Grid>
    </div>
  );
}

export default TakePaymentModalContent;
